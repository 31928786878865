<template>
  <main>

    <Head>
      <Title></Title>
    </Head>

    <div class="mt-3rem flex overflow-x-clip justify-center items-center flex-col font-italic">
      <div class="sm:pr-6rem md:pr-12rem animate-fade-in-left animate-ease">
        <span class="font-size-7rem sm:font-size-8rem font-bold">shirok1</span>
      </div>
      <div class="pl-6rem sm:pl-9rem md:pl-12rem -mt-4rem animate-fade-in-right animate-ease">
        <span class="font-size-5rem sm:font-size-6rem font-semibold color-gray">dot</span>
        <span class="font-size-5rem sm:font-size-6rem font-semibold">&nbsp;</span>
        <span class="font-size-7rem sm:font-size-8rem font-bold">dev</span>
      </div>
    </div>

    <div class="m-2rem flex justify-center font-size-32px animate-fade-in animate-ease">
      Stupid quotes about ambitions here.
    </div>
  </main>
</template>